import React, { Component } from "react";

import { Learning } from "../../components/learning/learning";
import Newsletter from "../../components/newsletter/newsletter";
import Section from "../../components/common/Section";
import Layout from "../../layouts";
import presidentStyles from "./compare-presidents.module.css";
import { Container, Row, Col } from "react-bootstrap";
import Helmet from "react-helmet"; 
import CompareSelector from "../../components/compare/CompareSelector"; 

import bidenImage from "../../../static/assets/biden.jpg";
import trumpImage from "../../../static/assets/trump.jpg";
import obamaImage from "../../../static/assets/obama.jpg";
import clintonImage from "../../../static/assets/clinton.jpg";

import Hero from "../../components/common/Hero";
import SEO from "../../components/common/SEO"; 

class ComparePresidentPage extends Component {
  render() {
    return (
      <Layout>
        <Helmet bodyAttributes={{ class: "compare-presidents-page" }} />        
        <SEO post={{
          title: "Compare US Presidential Performance",
          description: "Compare any 2 US presidents to see how they stack up on key economic metrics, such as GDP growth and stock market performance"

        }} />
        <Hero title="Compare Any Two Presidents"></Hero>        
        <CompareSelector />

        <Container className={"compare-two"}>
          <Row>
            <Col>
              <Section
                name="comparison"
                header="Our Most Popular Presidential Comparisons"
              >
                <Container>
                  <Row>
                    <Col lg={6}>
                    <a href="/comparison/joe-biden/donald-trump/">
                      <Row
                        className={
                          presidentStyles.compareGroup + " " + ["compare-unit"]
                        }
                      >
                        <Col className={presidentStyles.comparePresidentImageR}>
                          <img src={bidenImage} alt="Joe Biden" />
                        </Col>
                        <Col className={presidentStyles.comparePresidentName}>
                          Joe Biden
                        </Col>
                        <Col className={presidentStyles.compareLink}>
                          Compare
                        </Col>
                        <Col className={presidentStyles.comparePresidentName}>
                          Barack Obama
                        </Col>
                        <Col
                          className={presidentStyles.comparePresidentImageD}
                          style={{ textAlign: "right" }}
                        >
                          <img src={trumpImage} alt="Donald Trump" />
                        </Col>
                      </Row>
                      </a>
                    </Col>

                    <Col lg={6}>
                    <a href="/comparison/joe-biden/barack-obama/">
                      <Row
                        className={
                          presidentStyles.compareGroup + " " + ["compare-unit"]
                        }
                      >
                        <Col className={presidentStyles.comparePresidentImageD}>
                          <img src={bidenImage} alt="Joe Biden" />
                        </Col>
                        <Col className={presidentStyles.comparePresidentName}>
                          Joe Biden
                        </Col>
                        <Col className={presidentStyles.compareLink}>
                          Compare
                        </Col>
                        <Col className={presidentStyles.comparePresidentName}>
                          Barack Obama
                        </Col>
                        <Col
                          className={presidentStyles.comparePresidentImageD}
                          style={{ textAlign: "right" }}
                        >
                          <img src={obamaImage} alt="Barack Obama" />
                        </Col>
                      </Row>
                      </a>
                    </Col>
                  </Row>

                  <Row>
                    <Col lg={6}>
                    <a href="/comparison/donald-trump/barack-obama/">
                      <Row
                        className={
                          presidentStyles.compareGroup + " " + ["compare-unit"]
                        }
                      >
                        <Col className={presidentStyles.comparePresidentImageD}>
                          <img src={trumpImage} alt="Donald Trump" />
                        </Col>
                        <Col className={presidentStyles.comparePresidentName}>
                          Donald Trump
                        </Col>
                        <Col className={presidentStyles.compareLink}>
                          Compare
                        </Col>
                        <Col className={presidentStyles.comparePresidentName}>
                          Barack Obama
                        </Col>
                        <Col
                          className={presidentStyles.comparePresidentImageR}
                          style={{ textAlign: "right" }}
                        >
                          <img src={obamaImage} alt="Barack Obama" />
                        </Col>
                      </Row>
                      </a>
                    </Col>
                    <Col lg={6}>
                      <a href="/comparison/barack-obama/bill-clinton/">
                      <Row
                        className={
                          presidentStyles.compareGroup + " " + ["compare-unit"]
                        }
                      >
                        <Col className={presidentStyles.comparePresidentImageR}>
                          <img src={obamaImage} alt="Barack Obama" />
                        </Col>
                        <Col className={presidentStyles.comparePresidentName}>
                          Barack Obama
                        </Col>
                        <Col className={presidentStyles.compareLink}>
                          Compare
                        </Col>
                        <Col className={presidentStyles.comparePresidentName}>
                          Bill Clinton
                        </Col>
                        <Col
                          className={presidentStyles.comparePresidentImageR}
                          style={{ textAlign: "right" }}
                        >
                          <img src={clintonImage} alt="Bill Clinton" />
                        </Col>
                      </Row>
                      </a>
                    </Col>
                  </Row>
                </Container>
              </Section>
            </Col>
          </Row>
          <Row>
            <Col>
              <Learning />
            </Col>
          </Row>
          <Row>
            <Col>
              <Section
                name="metrics_calculation"
                header="How We Calculate Our Presidential Comparison Data"
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <Newsletter />
            </Col>
          </Row>
        </Container>
      </Layout>
    );
  }
}

export default ComparePresidentPage;

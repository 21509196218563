import React, { Component } from "react";
import { Container, Col, Row, Button } from "react-bootstrap"; 
import styled from "styled-components";
import PresidentImageLink from "../common/PresidentImageLink";
import { presidentSlug } from "../../utils/slugs";
import { navigate } from "gatsby"; 

const Column = styled(Col)`
  padding: 20px 0 10px;
  margin: auto;
`;

const NumberOverlay = styled.div`
  border: 2px solid rgb(255,103,0,0.50);
  background-color: rgb(255,103,0,0.20);  
  width: 125px; 
  height: 125px;  
  position: absolute;   
  z-index: 10;
  left: -5;
  border-radius: 50%; 
  cursor: pointer; 
  color: white; 
  text-align: center; 
  font-family: Lato,sans-serif;
  -webkit-text-stroke: 1px rgb(255,103,0,0.50);
	font-size: 4.5rem;
  line-height: 7.5rem;  
  transition: box-shadow .15s;
  box-shadow: 0 0  7px #666;
`

/**
 * Higher Order Component 
 * Component used to provide a selection model for users to interact with and pick two
 * presidents they would like to compare metrics against. 
 */
class CompareSelector extends Component {

  constructor(props) {
    super(props); 

    this.state = {
      selected: []
    }; 

    this.clickHandler = this.clickHandler.bind(this); 
    this.goHandler = this.goHandler.bind(this); 
  }

  /**
   * Adds and removes presidents to this components state to build the url to redirect on the site. 
   * @param {string} name 
   */
  clickHandler(name) {
    let selected = this.state.selected; 

    // this person is already selected do not modify state. 
    if(selected.indexOf(name) !== -1) {
      if(selected.indexOf(name) === 0) {
        selected.shift(); 
      } else {
        selected.pop(); 
      }
    } else {
      // if we have more than two presidents queue we will remove the last one. Before adding the new one
      if(this.state.selected.length && this.state.selected.length > 1) {
        selected.shift(); 
      }

      selected.push(name); 
    }

    this.setState( {selected} );    
  }

  /**
   * Used to determine if a president is selected. 
   * @param {string} name 
   */
  isSelected(name) {
    const found = this.state.selected.indexOf(name); 
    
    if(found === -1) {
      return false; 
    }

    return found+1; // + 1 for readability as indexes start at 0 
  }

  goHandler() {
    if(this.state.selected.length === 2) {
      let pres1 = presidentSlug(this.state.selected[0]); 
      let pres2 = presidentSlug(this.state.selected[1]); 

      navigate(`/comparison/${pres1}/${pres2}`); 
    }
  }

  /**
   * creates a row of presidents in the selector. 
   * @param {} presidents array of presidents by {id,name}
   * @param {*} onClick handler when a president image is clicked
   * @param {*} size size of the image
   */
  createRow(presidents = [], size=125) {

    return (
      presidents.map( (item) => {
        let selected = this.isSelected(item.name); 

        return <React.Fragment key={item.id}>
          <Column xs={6} md={2}>
            { selected && <NumberOverlay onClick={ () => { this.clickHandler(item.name) }}>{selected}</NumberOverlay> }
            <PresidentImageLink
              size={size}
              name={item.name}
              clickHandler={this.clickHandler}
              />
          </Column>
        </React.Fragment>
      })      
    );         
  }

  render() {    
    return (
      <Container className={"compare-select"}>
        <p>
          Pick on any two presidents we track and compare metrics between the
          two presidents. We have presidential comparisons on some or all
          metrics from every president since Harry Truman and we continue to
          work hard on adding more presidents and metrics.
        </p>
        <p>
          <strong>
            Select any two presidents below and hit GO or select from our most
            popular comparisons.
          </strong>
        </p>
        <Row>
          {this.createRow([
            { id: 1, name: "Joe Biden"},
            { id: 2, name: "Donald Trump"},
            { id: 3, name: "Barack Obama"},
            { id: 4, name: "George W Bush"},
            { id: 5, name: "Bill Clinton"}
          ])}          
        </Row>
        <Row>
          { this.createRow([
           { id: 1, name: "George H.W. Bush"},   
           { id: 2, name: "Ronald Reagan"},
           { id: 3, name: "Jimmy Carter"},
           { id: 4, name: "Gerald Ford"},
           { id: 5, name: "Richard Nixon"}            
          ])}
        </Row>
        <Row>
        { this.createRow([
           { id: 1, name: "Lyndon B. Johnson"},
           { id: 2, name: "John F. Kennedy"},
           { id: 3, name: "Dwight D. Eisenhower"},
           { id: 4, name: "Harry S. Truman"}          
          ])}
          <Column xs={6} md={2}>
            <Button onClick={ this.goHandler }>Go</Button>
          </Column>
        </Row>
      </Container>
    );
  }
}

export default CompareSelector;
